
import api from "@/api";
import { PasswordResetRequestModel } from "@/api/generated";
import Validation from "@/mixins/validation";
import SnackbarModule from "@/store/snackbarModule";
import { VForm } from "@/types/vForm";
import { Component, PropSync, Ref, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

const snackbarModule = getModule(SnackbarModule);

@Component
export default class AnonymousPasswordResetDialog extends Validation {
  @PropSync("dialog", { type: Boolean }) syncedDialog!: boolean;
  @Ref() private readonly form!: VForm;

  private loading = false;
  private passwordResetModel: PasswordResetRequestModel = {
    emailAddress: "",
  };

  @Watch("syncedDialog")
  private onDialogClose() {
    if (!this.syncedDialog) {
      return;
    }

    this.form?.reset();
  }

  private async onPasswordReset() {
    if (!this.form.validate()) {
      return;
    }

    try {
      this.loading = true;
      await api.AuthService.apiAuthorisationSendPasswordResetPost(
        this.passwordResetModel
      );
      snackbarModule.setSnackbarMessage("Password reset successfully sent");
    } catch {
      snackbarModule.setSnackbarMessage("Password reset failed");
    } finally {
      this.loading = false;
      this.syncedDialog = false;
    }
  }
}
